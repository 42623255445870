/* eslint-disable import/prefer-default-export */
export function generateMenu(userData) {
    const menu = []

    if (userData && userData.role === 'ROLE_ADMIN') {
        menu.push(
            {
                title: 'Customers',
                route: '/customers',
            },
            {
                title: 'Orders',
                route: '/',
            },
            {
                title: 'Products',
                route: '/products',
            },
            {
                title: 'Categories',
                route: '/categories',
            },
            {
                title: 'Stock',
                route: '/stock',
            },
            {
                title: 'Warehouses',
                route: '/warehouses',
            },
            {
                title: 'Couriers',
                route: '/couriers',
            },
            {
                title: 'Employees',
                route: '/employees',
            },
            {
                title: 'Cars',
                route: '/cars',
            },
            {
                title: 'OCI',
                route: '/oci',
            },
        )
    }

    if (userData && userData.role === 'ROLE_DISPATCHER') {
        menu.push(
            {
                title: 'Cars',
                route: '/dispatcher/cars',
            },
            {
                title: 'Customers',
                route: '/customers',
            },
            {
                title: 'Orders',
                route: '/dispatcher/orders',
            },
            {
                title: 'Payments',
                route: '/cash-register',
            },
        )
    }

    if (userData && (userData.role === 'ROLE_STOCK_MANAGER' || userData.role === 'ROLE_DISPATCHER')) {
        menu.push(
            {
                title: 'Stock',
                route: '/stock-managment',
            },
        )
    }

    menu.push(
        {
            title: 'Settings',
            route: '/settings',
        },
        {
            title: 'Log out',
            route: '/logout',
        },
    )

    return menu
}
