<template>
  <div id="main-navigation">
    <router-link to="/" class="logo">
      <img src="@/assets/images/logo.svg" alt="logo" />
    </router-link>
    <div class="navigation-container">
      <!-- Main navigation links -->
      <nav id="navigation-links">
        <router-link v-for="(item, index) of menu" :key="index" :to="item.route">{{ item.title }}</router-link>
      </nav>
      <!-- User circle info -->
      <router-link to="/" class="user">
        <b-list-group>
          <b-list-group-item class="user-circle">
            <b-avatar
              badge
              class="user-photo"
              variant="primary"
              icon="star-fill"
              :src="user.fileUrl ? apiUrl + user.fileUrl : ''"
              badge-variant="success"
            />
            <div class="user-info">
              {{user.givenName}} {{user.familyName}}
              <span>{{ $helper.getEnumTranslation('roles',  user.role) }}</span>
            </div>
          </b-list-group-item>
        </b-list-group>
      </router-link>
    </div>
  </div>
</template>

<script>
import { BAvatar, BListGroup, BListGroupItem } from 'bootstrap-vue'
import { getUserData } from "@/auth/utils"
import { generateMenu } from './menu'

export default {
  name: 'NavBar',
  components: {
    BAvatar,
    BListGroup,
    BListGroupItem,
  },
  data() {
    return {
      user: getUserData(),
      apiUrl: this.$store.state.apiUrl,
      menu: null
    }
  },
  created() {
    this.menu = generateMenu(this.user)
  }
}
</script>
